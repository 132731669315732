import { render, staticRenderFns } from "./AddDialog.vue?vue&type=template&id=45959de6&scoped=true&"
import script from "./AddDialog.vue?vue&type=script&lang=js&"
export * from "./AddDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45959de6",
  null
  
)

export default component.exports